import React from "react"
import { Link } from "gatsby"
import herobg from "../img/hero-bg.png"
import Header from "../components/header"
import Footer from "../components/footer"
import styled, { createGlobalStyle } from "styled-components"
import { colours } from "../components/constants"
import { device } from "../components/constants"
import SEO from "../components/seo"

const GlobalStyles = createGlobalStyle`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: light;
    color: ${colours.brown};
  }

  a {
    text-decoration:none;
    color: ${colours.brown};
  }

  nav {
    font-family:Dosis;
  }
`

const Wrapper = styled.div`
  align-content: center;
  padding-left: calc((100vw - 1200px) / 2);
  padding-right: calc((100vw - 1200px) / 2);

  @media ${device.tablet} {
    padding: 10px;
  }

  @media ${device.mobile} {
  }
`

const Content = styled.main`
  @media ${device.tablet} {
  }

  @media ${device.mobile} {
  }
`

export default ({ children }) => {
  return (
    <Wrapper>
      <GlobalStyles />

      <Header />

      <Content>{children}</Content>

      <Footer />
    </Wrapper>
  )
}
