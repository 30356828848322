import React from "react"
import { Link } from "gatsby"
import { FaBars } from "react-icons/fa"
import styled from "styled-components"
import hero from "../img/hero.jpg"
import logoSource from "../img/logo.svg"
import { colours, device } from "./constants"

const HeaderContainer = styled.header`
  display: grid;
  grid-template-columns: 200px 1fr;
  padding: 20px 0 0 0;

  @media ${device.tablet} {
    grid-template-columns: 1fr;
  }

  @media ${device.mobile} {
  }
`

const Grid = styled.div`
  display: grid;
`

const Hero = styled(Grid)`
  margin: 5px 0 0 0;
  grid-column-start: span 2;

  @media ${device.tablet} {
    grid-column-start: 1;

    img {
      width: 100%;
    }
  }

  @media ${device.mobile} {
  }
`

const Logo = styled(Grid)`
  @media ${device.tablet} {
    text-align: center;
  }
`

const LogoImage = styled.img`
  height: 50px;
`

const Nav = styled.div`
  margin: 15px 0 0 0;

  a {
    font-size: 1.4em;
    font-family: Dosis;
    text-decoration: none;
  }

  ul {
    float: right;
  }

  li {
    list-style-type: none;
    float: left;
    margin-left: 30px;
  }

  @media ${device.tablet} {
    display: grid;
    margin: 0;
    justify-content: center;

    a {
      font-size: 1.2em;
    }

    ul {
      margin: 0;
      padding: 0;
    }

    li {
      margin-left: 0;
      margin-right: 15px;
    }

    li:first-child {
      display: none;
    }
  }

  @media ${device.mobile} {
  }
`

const Header = class extends React.Component {
  render() {
    return (
      <HeaderContainer>
        <Logo>
          <Link to="/">
            <LogoImage src={logoSource} alt="Candeo Design Logo" />
          </Link>
        </Logo>

        <Nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/profile">Profile</Link>
            </li>
            <li>
              <Link to="/services">Services</Link>
            </li>
            <li>
              <Link to="/folio">Folio</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </Nav>

        <Hero>
          <Link to="/folio/show-gardens/mifgs-2018-square-1">
            <img src={hero} alt="" />
          </Link>
        </Hero>
      </HeaderContainer>
    )
  }
}

export default Header
