const colours = {
  brown: "#726658",
}

const device = {
  mobile: `only screen and (max-width: 639px)`,
  tablet: `only screen and (max-width: 1023px)`,
  desktop: `only screen and (min-width: 1024px)`,
}

export { colours, device }
