import React from "react"
import { Link } from "gatsby"
import { FaFacebook, FaPhone, FaInstagram } from "react-icons/fa"

import styled from "styled-components"
import { colours, device } from "./constants"

const FooterContainer = styled.footer`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  section:nth-child(2) p {
    text-align: center;
  }

  section:last-child p {
    text-align: right;
  }

  @media ${device.tablet} {
  }

  @media ${device.mobile} {
    grid-template-columns: 1fr;
    text-align: center;

    section:last-child p {
      text-align: center;
    }
  }

  svg {
    color: ${colours.brown};
    margin: 0 7px 0 0;
    font-size: 1.6em;
  }
`

const Footer = () => (
  <FooterContainer>
    <section>
      <p>
        <a
          href="https://www.facebook.com/candeodesign"
          title="Click to visit our Facebook Page"
        >
          <FaFacebook />
        </a>

        <a
          href="https://www.instagram.com/candeodesign/"
          title="Click to visit our Instagram Page"
        >
          <FaInstagram />
        </a>
      </p>
    </section>

    <section>
      <p>
        Copyright &copy; 2019 <Link to="/">Candeo Design</Link>
      </p>
    </section>

    <section>
      <p>
        <a href="tel:+61423080728" title="Click to call us">
          <FaPhone />
        </a>
      </p>
    </section>
  </FooterContainer>
)

export default Footer
